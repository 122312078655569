import React from 'react';
import UserSignup from '../components/User/UserSignup/UserSignup';

const SignIn = () => {
  return (
    <div>
      <UserSignup/>
    </div>
  )
}

export default SignIn
