import React from 'react'
import AboutContent from '../components/PageContent/AboutContent/AboutContent'




const About = () => {
  return (
    <div>
     <AboutContent></AboutContent>
     

    </div>
  )
}

export default About