import React from 'react'
import ServicesContent from '../components/PageContent/ServicesContent/ServicesContent'



const Services = () => {
  return (
    <div>
    <ServicesContent/>
    </div>
  )
}

export default Services