import React from 'react'
import ProductsContent from '../components/PageContent/ProductsContents/ProductsContent'



const Product = () => {
  return (
    <div>
     <ProductsContent></ProductsContent>
    </div>
  )
}

export default Product