import React from 'react'
import UserLogin from '../components/User/UserLogin/UserLogin'



const Login = () => {
  return (
    <div>
      <UserLogin></UserLogin>
    </div>
  )
}

export default Login