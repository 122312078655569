import React from 'react'
import HomeContent from '../components/PageContent/HomeContent/HomeContent'




const Home = () => {
  return (
    <>
      <HomeContent/>
      
    </>
  )
}

export default Home